<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI演讲稿" />
                        <ai_select selectTitle="使用场合" :select_list="speech_occasion_list"
                            @getSelectVal="get_speech_occasion" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.theme_name" placeholder="请录入主题名称。如：学会感恩"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">面向人群：</p>
                            <div class="upload_box">
                                <el-input v-model="form.face_crowd" placeholder="描述提醒：请录入此演讲稿面向人群。如：高中学生"></el-input>
                            </div>
                        </div>
                        <ai_select selectTitle="字数上限" :select_list="num_list" @getSelectVal="get_num" />
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                theme_name: '',
                face_crowd: '',
                speech_occasion: '',
                speech_num: '',
                speak_style: '',
                speech_num: ''
            },
            speech_occasion_list: [
                {
                    id: '团队领导',
                    name: '团队领导'
                },
                {
                    id: '公司大会',
                    name: '公司大会'
                },
                {
                    id: '外部发布会',
                    name: '外部发布会'
                },
                {
                    id: '特定专题会',
                    name: '特定专题会'
                },
            ],
            num_list: [
                {
                    id: '500',
                    name: '500'
                },
                {
                    id: '1000',
                    name: '1000'
                },
                {
                    id: '1500',
                    name: '1500'
                },
            ],
            contentData: {}, //内容
            selectList: ['美食', '自然', '专题', '历史', '生活', '宣传', '其他'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_speech_occasion(val) {
            this.form.speech_occasion = val
        },
        get_num(val) {
            this.form.speech_num = val
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            this.curlPost('/file/create_speechtext', {
                theme_name: this.form.theme_name,
                face_crowd: this.form.face_crowd,
                speech_occasion: this.form.speech_occasion,
                speech_num: this.form.speech_num,
                speak_style: this.form.speak_style,
                speak_num: this.form.speak_num,
                mobile: this.$user_info.mobile,
            }).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()
                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai.scss'
</style>